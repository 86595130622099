import Home from './Components/Pages/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import "./Components/Sass/_color.scss"
import "./Components/Sass/_common.scss"
import "./Components/Sass/_media-queries.scss"
import "./Components/Sass/_responsive.scss"
import "./Components/Sass/_typography.scss"
import "./Components/Sass/_variables.scss"
import "./Components/Sass/style.css"

// import "./Components/Sass/style.scss"

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
