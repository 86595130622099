import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/storage'

firebase.initializeApp({
    apiKey: "AIzaSyAHrSKuariitOZHggc5TFhI-b4UkRuVcUc",
    authDomain: "gc-venture.firebaseapp.com",
    projectId: "gc-venture",
    storageBucket: "gc-venture.appspot.com",
    messagingSenderId: "583146482117",
    appId: "1:583146482117:web:c7cfae48ddd466342243ac",
    measurementId: "G-MXQRG17L4Y"
});

const fb = firebase;

export default fb;