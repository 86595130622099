import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer id="footer" className="bg-one">
            <div className="top-footer">
                <div className="container">
                    <div className="row justify-content-around">
                        <div className="col-lg-4 col-md-6 mb-5 mb-lg-0">
                            <h3>about</h3>
                            <p>
                                We aim to be your trusted partner in navigating the ever-evolving world of technology. We offer a comprehensive suite of IT services designed to streamline your operations, enhance productivity, and ensure your business stays ahead of the curve.
                            </p>
                        </div>
                        {/* End of .col-sm-3 */}
                        {/* <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
                            <ul>
                                <li>
                                    <h3>Our Services</h3>
                                </li>
                                <li>
                                    <a href="service.html">Ui/Ux Design</a>
                                </li>
                                <li>
                                    <a href="service.html">Graphic Design</a>
                                </li>
                                <li>
                                    <a href="service.html">Web Design</a>
                                </li>
                                <li>
                                    <a href="service.html">Web Development</a>
                                </li>
                            </ul>
                        </div> */}
                        {/* End of .col-sm-3 */}
                        <div className="col-lg-2 col-md-6 mb-5 mb-md-0">
                            <ul>
                                <li>
                                    <h3>Quick Links</h3>
                                </li>
                                <li>
                                    <Link to={"/"}>Home</Link>
                                </li>
                                <li>
                                    <a href="#about">About</a>
                                </li>
                                <li>
                                    <a href="#services">Services</a>
                                </li>
                                {/* <li>
                                    <a href="#blogs">Blogs</a>
                                </li> */}
                                <li>
                                    <a href="#contact">Contact</a>
                                </li>
                            </ul>
                        </div>
                        {/* End of .col-sm-3 */}
                        <div className="col-lg-3 col-md-6">
                            <ul>
                                <li>
                                    <h3>Connect with us Socially</h3>
                                </li>
                                <li>
                                    <a href="https://www.google.com/maps?ll=28.411988,77.043239&z=13&t=m&hl=en&gl=IN&mapclient=embed&cid=2693751191648049772">Google</a>
                                </li>
                                <li>
                                    <a href="https://wa.me/919999647333/">Whatsapp</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        Instagram
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        Facebook
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14036.963526412246!2d77.0432389!3d28.4119876!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d2374d0652631%3A0x2562206fcb779a6c!2sG%20C%20Venture%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1719657757184!5m2!1sen!2sin" style={{ border: "4px solid #fff" }} width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        {/* End of .col-sm-3 */}
                    </div>
                </div>{" "}
                {/* end container */}
            </div>
            <div className="footer-bottom">
                <h5>© Copyright 2024. All rights reserved.</h5>
                <h6>
                    Design and Developed by{" "}
                    <a style={{ color: "#fff" }} href="https://www.ahsassuredservices.com/">AHS Assured Services</a>
                </h6>
            </div>
        </footer>
    )
}

export default Footer